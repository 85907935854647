import Loader from "react-loader-spinner";

const Spinner = () => {
  return (
    <di className="loader">
      <div className="loader_center">
         <Loader
          type="ThreeDots"
          color="#FFFFFF"
          height={100}
          width={100}
        />
      </div>
    </di>
    
  );
}

export default Spinner