
import React , {useEffect} from 'react'

//Rutas
import { BrowserRouter, Redirect, Route, outer, Switch } from 'react-router-dom';

//paginas
import Home from './components/home/Home'

//estilos
import './styles/app.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {

  const redirectNotFound = () =>{
   <Redirect  to="/"></Redirect>
  }
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} ></Route>
        </Switch>    
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
